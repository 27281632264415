import { default as _91slug_93Aon7ycQ84xMeta } from "/home/forge/alfasa.com.tr/pages/[slug].vue?macro=true";
import { default as addressWB3B1WSJxaMeta } from "/home/forge/alfasa.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_93taLFuw8F27Meta } from "/home/forge/alfasa.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93gsEMp9LX9IMeta } from "/home/forge/alfasa.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexZDuAwk10KOMeta } from "/home/forge/alfasa.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewwytb1TjarEMeta } from "/home/forge/alfasa.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_935Zmnt8i0xnMeta } from "/home/forge/alfasa.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexERFIJUyod9Meta } from "/home/forge/alfasa.com.tr/pages/account/statement/index.vue?macro=true";
import { default as useraXvoyOlXs3Meta } from "/home/forge/alfasa.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistWX3HMPuaFPMeta } from "/home/forge/alfasa.com.tr/pages/account/wishlist.vue?macro=true";
import { default as carteuuRCRCifwMeta } from "/home/forge/alfasa.com.tr/pages/cart.vue?macro=true";
import { default as indexarMRgFbXUoMeta } from "/home/forge/alfasa.com.tr/pages/checkout/index.vue?macro=true";
import { default as paymentnnXb6TNXjfMeta } from "/home/forge/alfasa.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientrsrE2ejQcgMeta } from "/home/forge/alfasa.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/alfasa.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestGEjWtR74jhMeta } from "/home/forge/alfasa.com.tr/pages/customer/request.vue?macro=true";
import { default as successySHAQR1I37Meta } from "/home/forge/alfasa.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisimqo0Z2QIhD9Meta } from "/home/forge/alfasa.com.tr/pages/iletisim.vue?macro=true";
import { default as index25Pe76xZyFMeta } from "/home/forge/alfasa.com.tr/pages/index.vue?macro=true";
import { default as loginHKlCAjEnrFMeta } from "/home/forge/alfasa.com.tr/pages/login.vue?macro=true";
import { default as offerkpMkYChFtOMeta } from "/home/forge/alfasa.com.tr/pages/offer.vue?macro=true";
import { default as statusyeHIGPoPCHMeta } from "/home/forge/alfasa.com.tr/pages/order/status.vue?macro=true";
import { default as trackBUinZfKN7FMeta } from "/home/forge/alfasa.com.tr/pages/order/track.vue?macro=true";
import { default as forgot2fzilpUm69Meta } from "/home/forge/alfasa.com.tr/pages/password/forgot.vue?macro=true";
import { default as resetsief1SfbBDMeta } from "/home/forge/alfasa.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationgcapIMjlOKMeta } from "/home/forge/alfasa.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryN0vCmei9sNMeta } from "/home/forge/alfasa.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerMIo2ACUEMuMeta } from "/home/forge/alfasa.com.tr/pages/register.vue?macro=true";
import { default as searchTm8r7YoaNEMeta } from "/home/forge/alfasa.com.tr/pages/search.vue?macro=true";
import { default as locationsPzy4EZDaCNMeta } from "/home/forge/alfasa.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93Aon7ycQ84xMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressWB3B1WSJxaMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93taLFuw8F27Meta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93gsEMp9LX9IMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexZDuAwk10KOMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewwytb1TjarEMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_935Zmnt8i0xnMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexERFIJUyod9Meta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: useraXvoyOlXs3Meta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistWX3HMPuaFPMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/alfasa.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexarMRgFbXUoMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentnnXb6TNXjfMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/alfasa.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/alfasa.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/alfasa.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/alfasa.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: index25Pe76xZyFMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginHKlCAjEnrFMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerkpMkYChFtOMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/alfasa.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/alfasa.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgot2fzilpUm69Meta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetsief1SfbBDMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/alfasa.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/alfasa.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerMIo2ACUEMuMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchTm8r7YoaNEMeta || {},
    component: () => import("/home/forge/alfasa.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/alfasa.com.tr/modules/locationsPage/pages/locations.vue")
  }
]